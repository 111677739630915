<template>
  <ModuleForm
    ref="rightNowForm"
    :module="rightNow"
    :module-name="$t('moduleForm.rightNow')"
    :show-header-info="disabled"
  >
    <template #header-buttons>
      <ModuleFormButton
        v-if="rightNow.id"
        :title="$t('buttons.publish')"
        :required-permissions="requiredPermissions.publishButton"
        @click="publish"
        :disabled="!!rightNow.publishedAt"
      />
      <ModuleFormButton
        v-if="disabled"
        :required-permissions="requiredPermissions.editButton"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :title="$t('buttons.save')"
        :required-permissions="requiredPermissions.saveButton"
        @click="save"
      />
      <ModuleFormDeleteButton
        v-if="rightNow.id"
        :record-id="rightNow.id"
        :required-permissions="requiredPermissions.deleteButton"
        store-name="rightNow"
        list-router-path="right-now"
      />
      <ModuleFormButton
        icon="close"
        @click="goToList"
      />
    </template>

    <template #form-content>
      <Preloader v-if="callingAPI" on-top/>
      <div class="row">
        <Select class="col-lg-3"
                id="rightNow_type"
                :options="typeOptions"
                v-model="rightNow.type"
                :label="$t('rightNow.type')"
                :disabled="disabled"
                no-empty-value
                required
        />
        <Input class="col-lg-3"
               @blur="$v.rightNow.source.$touch()"
               :error="$v.rightNow.source.$error"
               id="rightNow_source"
               v-model="rightNow.source"
               :label="$t('rightNow.source')"
               :disabled="disabled"
               :maxlength="100"
               required
        />
        <Multiselect
          class="col-lg-6"
          v-model="badge"
          :options="[]"
          :internal-search="false"
          :show-no-results="false"
          :searchable="false"
          :disable-opening="true"
          id="rightNow.badge"
          label="title"
          :labelDesc="$t('rightNow.badge')"
          track-by="id"
          disabled/>
      </div>

      <div class="row">
        <Input
          class="col-lg-3"
          @blur="$v.rightNow.title.$touch()"
          :error="$v.rightNow.title.$error"
          id="rightNow_title"
          v-model="rightNow.title"
          :label="$t('rightNow.title')"
          :disabled="disabled"
          :maxlength="170"
          required
        />
        <Input
          class="col-lg-7"
          @blur="$v.rightNow.text.$touch()"
          :error="$v.rightNow.text.$error"
          id="rightNow_text"
          v-model="rightNow.text"
          :label="$t('rightNow.text')"
          :disabled="disabled"
          :maxlength="170"
          required
        />
        <InputCharacterCount
          :title="rightNow.title"
          :text="rightNow.text"
          class="col-lg-2"
          label="rightNow.character_count"
          label-from="rightNow.from"
          disabled
        />
      </div>

      <div class="row">
        <Input
          class="col-lg-5"
          @blur="$v.rightNow.hyperlink.$touch()"
          :error="$v.rightNow.hyperlink.$error"
          id="rightNow_hyperlink"
          v-model="rightNow.hyperlink"
          :label="$t('rightNow.hyperlink')"
          :disabled="disabled"
          input-type="url"
        />
        <Datepicker
          class="col-lg-3"
          type="datetime"
          v-model="rightNow.date"
          :label="$t('rightNow.date')"
          :disabled="disabled"
        />
        <Datepicker
          class="col-lg-2"
          type="datetime"
          v-model="rightNow.publishedAt"
          :label="$t('rightNow.published_at')"
          disabled
        />
        <Datepicker
          class="col-lg-2"
          type="datetime"
          v-model="rightNow.modifiedAt"
          :label="$t('rightNow.modified_at')"
          disabled
        />
      </div>

      <div class="row">
        <div class="col-lg-8 right-now-multiselect">
          <Multiselect
            class="right-now-multiselect__input"
            :value="siteTags"
            :options="[]"
            :internal-search="false"
            :show-no-results="false"
            :searchable="false"
            :disable-opening="true"
            label="title"
            track-by="id"
            id="rightNow_sites"
            :label-desc="$tc('modal.journalsselection.sites_label')"
            :disabled="disabled || disabledSiteSelection"
            @blur="$emit('blur', $event)"
            :error="$v.rightNow.sites.$error"
            @remove="removeSite"
            required
          />

          <button @click="openModal"
                  class="right-now-multiselect__button"
                  :disabled="disabled || disabledSiteSelection">
            {{ $t('buttons.select') }}
          </button>
        </div>

        <div class="col-lg-4 mt-2 px-3 right-now-checkbox">
          <OutlinedCheckbox
            v-model="rightNow.shouldNotify"
            id="rightNow_shouldNotify"
            :label="$t('rightNow.should_notify')"
            :disabled="disabled"
          />
        </div>

        <JournalsSelectionModal
          ref="journalsSelectionModal"
          @onSelectedSites="handleSelectedSites"
          :defaultSelectedSiteIds="siteIds"
          :enabled-sites="enabledSites"
          :regions="regions"
          :disabled-buttons="['selectAll', 'selectDisplayed']"
          region-tags
        />
      </div>
    </template>
  </ModuleForm>
</template>

<script>
import RightNowModel, {
  RIGHT_NOW_TYPE_NATIONAL,
  RIGHT_NOW_TYPE_REGIONAL
} from '@/model/RightNowModel'
import NotifyService from '@/services/NotifyService'
import ModuleForm from '@/components/shared/ModuleForm'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import PermissionService from '@/services/PermissionService'
import Input from '@/components/form/inputs/Input.vue'
import Select from '@/components/form/select/Select.vue'
import Preloader from '@/components/preloader/Preloader.vue'
import { mapState } from 'vuex'
import Datepicker from '@/components/form/Datepicker'
import { maxLength, minLength, required, url } from 'vuelidate/lib/validators'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox.vue'
import InputCharacterCount from '@/views/rightNow/components/InputCharacterCount.vue'
import Multiselect from '@/components/form/select/MultiSelect.vue'
import JournalsSelectionModal from '@/components/shared/JournalsSelectionModal.vue'
import { MODULE_MODULES_RIGHT_NOW } from '@/model/ValueObject/UserPermissionModules'
import { SITE_ID_DENIK_CZ } from '@/model/SiteModel'

export default {
  name: 'RightNowNewView',
  components: {
    Multiselect,
    JournalsSelectionModal,
    InputCharacterCount,
    OutlinedCheckbox,
    Datepicker,
    Preloader,
    Input,
    Select,
    ModuleForm,
    ModuleFormDeleteButton,
    ModuleFormButton
  },
  data () {
    return {
      rightNow: this._.cloneDeep(RightNowModel),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.RIGHT_NOW_PERMISSIONS,
      permissionModule: MODULE_MODULES_RIGHT_NOW,
      typeOptions: [
        {
          id: RIGHT_NOW_TYPE_NATIONAL,
          title: this.$t('rightNow.national')
        },
        {
          id: RIGHT_NOW_TYPE_REGIONAL,
          title: this.$t('rightNow.regional')
        }
      ],
      characterLimit: 170
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    characterCountValidator () {
      return this.rightNow.title.length + this.rightNow.text.length <= this.characterLimit
    },
    siteIds () {
      return this.rightNow.sites.map(site => site.id)
    },
    siteTags () {
      const regions = this.regions
        .map(region => {
          const regionSites = this.enabledSites.filter(site => site.regionId === region.id)
          const selectedRegion = regionSites.length && regionSites.every(site => this.siteIds.includes(site.id))
          return selectedRegion ? { ...region, title: region.name } : null
        })
        .filter(Boolean)
      const sites = this.enabledSites.filter(site => this.siteIds.includes(site.id) && !regions.some(region => region.id === site.regionId))
      return [...regions, ...sites]
    },
    rightNowType () {
      return this.rightNow.type
    },
    disabledSiteSelection () {
      return this.rightNow.type === RIGHT_NOW_TYPE_NATIONAL
    },
    badge () {
      return this.rightNow.source ? [{ id: 0, title: this.rightNow.source }] : []
    },
    enabledSites () {
      return this.$store.getters['site/enabledSites'](this.permissionModule)
    },
    regions () {
      return this.$store.getters['site/regions']
    }
  },
  async mounted () {
    this.rightNow.sites = [this.$store.getters['site/siteById'](SITE_ID_DENIK_CZ)]
    await this.$store.dispatch('site/fetchRegions')
  },
  watch: {
    rightNowType (newValue) {
      if (newValue === RIGHT_NOW_TYPE_NATIONAL) {
        this.rightNow.sites = [this.$store.getters['site/siteById'](SITE_ID_DENIK_CZ)]
      }
    }
  },
  methods: {
    openModal () {
      if (!this.disabledSiteSelection) {
        this.$refs.journalsSelectionModal.open()
      }
    },
    handleSelectedSites (sites) {
      this.rightNow.sites = sites
    },
    async getRightNow () {
      await this.$store.dispatch('rightNow/fetchOne', this.$route.params.id)
      const detail = this.$store.getters['rightNow/detail']
      const sites = detail.sites.map(site => this.$store.getters['site/siteById'](site.id))
      this.rightNow = { ...detail, sites }
    },
    async publish () {
      await this.$store.dispatch('rightNow/publish', this.rightNow)
      if (this.$store.getters['rightNow/error'] === null) {
        NotifyService.setSuccessMessage(this.$t('notify.record_was_published'))
        this.goToList()
      } else {
        NotifyService.setErrorMessage(this.$store.getters['rightNow/error'])
      }
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      const action = this.rightNow.id ? 'update' : 'create'
      const successNotify = this.rightNow.id ? 'record_was_updated' : 'record_was_created'
      await this.$store.dispatch(`rightNow/${action}`, this.rightNow)
      if (this.$store.getters['rightNow/error'] === null) {
        NotifyService.setSuccessMessage(this.$t(`notify.${successNotify}`))
        this.rightNow.id = this.$store.getters['rightNow/detail'].id
        this.goToEdit()
      } else {
        NotifyService.setErrorMessage(this.$store.getters['rightNow/error'])
      }
    },
    goToEdit () {
      this.$router.push('/right-now/' + this.rightNow.id + '/edit')
    },
    goToList () {
      this.$router.push('/right-now/')
    },
    removeSite (item) {
      if (item.type === 'region') {
        this.rightNow.sites = this.rightNow.sites.filter(site => site.regionId !== item.id)
      } else {
        this.rightNow.sites = this.rightNow.sites.filter(site => site.id !== item.id)
      }
    }
  },
  validations () {
    return {
      rightNow: {
        source: {
          required,
          maxLength: maxLength(100)
        },
        title: {
          required,
          combinedLength: {
            $validator: function () {
              return this.characterCountValidator
            }
          }
        },
        text: {
          required,
          combinedLength: {
            $validator: function () {
              return this.characterCountValidator
            }
          }
        },
        hyperlink: {
          url
        },
        sites: {
          required,
          minLength: minLength(1)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.right-now-multiselect {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  &__input {
    flex: 1;

    ::v-deep .multiselect__tags {
      border-radius: rem(6px) 0 0 rem(6px) !important;
    }
  }

  &__button {
    height: calc(100% - 24px);
    background: #6599FE;
    color: #fff;
    border: none;
    border-radius: 0 rem(6px) rem(6px) 0;
    padding: 0 40px;
    cursor: pointer;

    &:disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.6;
    }
  }
}

.right-now-checkbox {
  .checkbox {
    height: rem(42px);
  }
}
</style>
